

$base-font: "Helvetica Neue", Helvetica, Arial, sans-serif;

//  ------------------------------------------------
//  sizes
//  ------------------------------------------------
$header-height-mobile: 60px;
$header-height: 70px;

$border-radius: 4px;

$sidebar-width: 300px;

$widget-width: 370px;

$slideout-width: 370px;


$input-height: 42px;
$input-height-desktop: 36px;

$container-max-width: 1170px;
$gutter: 24px;

// z-index
$z1: 100;
$z2: 200;
$z9: 900;



$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);


// rc-datepicker

//overriding default sass variables with my project's colors
$react-dates-color-primary: $blue;
$react-dates-color-primary-dark: $blue;
$react-dates-color-gray: $gray;
$react-dates-color-placeholder-text: $gray;
$react-dates-color-gray-dark: $body;

// new
$new-input-height: 56px;
