@import "resources.scss";
.tcp-trip-routes {
    position: relative;
    margin: 0 70px;
    padding-bottom: 15px;
    padding-left: 5px;
    position: relative;
    &:before {
        content: "";
        @include pos(9px 0 n 0);
        background-color: $dark-gray;
        height: 3px;
        width: 100%;
    }
    &-icon {
        background: white;
    }
    &-time {
        font-weight: bold;
    }
    &-item {
        display: inline-block;
        float: left;
        overflow: hidden;
        position: relative;
        &:first-child,
        &:last-child {
            overflow: visible;
            white-space: nowrap;
            @include pos(0 n n n);
        }
        &:first-child {
            left: -70px;
        }
        &:last-child {
            right: -70px;
            margin-right: 0;
        }
    }
    &-time {
        @extend %flex-row;
        @extend %is-font-large;
        justify-content: space-between;
        margin-bottom: 4px;
        sup {
            position: absolute;
            right: 0;
        }
    }
    &-icon {
        display: none;
    }
    @include media-breakpoint-up(sm) {
        margin: 30px 20px 0;
        padding-right: 5px;
        padding-bottom: 43px;
        &:before {
            top: 13px;
            height: 1px;
            background-color: $lighter-gray;
        }
        padding-bottom: 40px;
        &-item {
            &:first-child,
            &:last-child {
                top: -30px;
            }
            &:first-child {
                left: -20px;
            }
            &:last-child {
                right: -20px;
                .icon {
                    margin-left: auto;
                }
            }
        }
        &-item-public {
            color: $light-gray;
            .tcp-leg-waypoint {
                &:before {
                    background-color: $light-gray;
                }
                &:after {
                    border-color: transparent transparent transparent $light-gray;
                }
                &-icon {
                    color: $light-gray;
                    border-color: $light-gray;
                }
            }
        }
        &-icon {
            display: block;
            &.icon-to {
                float: right;
            }
        }
        &-time {
            margin-bottom: 13px;
        }
    }
}
