@import "resources.scss";
.tcp-header {
    line-height: $header-height;
    border-bottom: 1px solid $lighter-gray;
    background-color: #fff;
    z-index: $z2;
    display: block;
    height: $header-height;
    position: fixed;
    top: 0;
    width: 100%;

    &-row {
        display: flex;
        align-items: center;
        padding: 0 15px;
        height: 100%;
    }

    &-countless {
        .steps-link {
            &:before {
                display: none;
            }
        }
    }

    &-border {
        border-bottom: 1px solid $light-gray;
    }

    &.is--transparent {
      background-color: transparent;
      border-bottom: 0;
    }

    @include media-breakpoint-up(sm) {
        &-row {
            padding: 0 20px;
        }
    }
}
