@import "resources.scss";
.tcp-trip-details {
  &-footer {
    display: flex;
    border-top: 1px solid $border-gray;
    align-items: center;
    width: 100%;

    .button {
      margin-left: auto;
      margin-right: 0;
    }
  }

  &-inner {
    padding: 0 20px;
  }

  &-footer-price {
    @extend %is-font-extralarge;
  }

  @include media-breakpoint-down(sm) {
    &-footer {
      padding: 17px 20px;
      margin: 0 -20px;
      position: fixed;
      bottom: 0;
      width: 100%;
      background: white;
      z-index: 1;
    }

    &-inner {
      padding-bottom: 75px;
    }
  }


  @include media-breakpoint-up(sm) {
    padding: 0px 0 0 0;
    border-top: 1px solid $border-gray;

    &-footer {
      border-top: 1px dashed $border-gray;
      padding: 25px 0;
      margin: 0;
    }

    &-footer-price {
      display: none;
    }

    // .spinner {
    //     margin-bottom: 28px;
    //     margin-top: 0px;
    // }
  }
}
