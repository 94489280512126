@import "resources.scss";
.tcp-trip-time-info {

  &-duration {
    padding-right: 20px;
  }

  @include media-breakpoint-up(sm) {
    display: block;
    padding-bottom: 10px;

    &-duration {
      display: block;
      padding-bottom: 10px;
      font-weight: bold;
    }
  }
}
