@import "resources.scss";
.tcp-tooltip {
  font-family: $base-font;
  display: inline-block;
  position: relative;
  opacity: 1;

  &.tooltip-left {
    text-align: left;
  }

  &-header {
    @extend %is-font-small;

    color: $gray;
    display: flex;
    align-items: center;

    i {
      display: block;
      font-size: 8px;

      &.lp-icon-terms {
        margin-right: 3px;
      }

      &:before {
        margin-right: 2px;
      }
    }

    &-crossed {
      font-weight: bold;
      text-decoration: line-through;
      margin-left: 5px;
    }
  }

  &-tip {
    display: inline-block;
    position: absolute;
    bottom: auto;
    border: 10px solid transparent;
    border-top: 0;
    right: 20px;
    z-index: 2;
    top: -10px;
    border-bottom-color: #fff;
  }

  &-body {
    @include light-glow;

    position: absolute;
    padding: 10px 20px;
    z-index: $z1;
    background: white;
    border-radius: 3px;
    right: 0;
    top: 33px;
    text-align: left;
    display: none;
    width: auto;
    white-space: nowrap;
    max-width: 90vw;

    &-text {
      @extend %is-font-small;

      &:nth-child(2) {
        margin-top: 7px;
      }
    }

    &-header {
      @extend %is-font-medium;

      font-weight: bold;
      padding-bottom: 10px;
    }

    &.is-open {
      display: block;
    }

    &.is-large {
      white-space: normal;
      width: calc( 100vw - 39px);
    }

    &.align-left {
      transform: translateX(-15px);
    }
  }

  @include media-breakpoint-down(sm) {
    // z-index: 1;

    &-body.align-left {
      right: auto;
      width: 260px;

      .tcp-tooltip-tip {
        right: auto;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    text-align: right;
    z-index: inherit;

    &-header {
      color: $body;
      padding: 0 0px 5px;

      i {
        font-size: 10px;

        &.big {
          font-size: 15px;
        }

        &.spaced {
          padding: 0 10px;
        }

        &:before {
          margin-right: 5px;
        }
      }
    }

    &-body {

      &.align-left {
        transform: translateX(15px);
      }

      &.is-large {
        width: 350px;
      }
    }
  }
}
