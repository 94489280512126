@import "resources.scss";
.tcp-trip {
  @extend %list-style;
  border-radius: 0px;
  display: flex;
  flex-direction: column;

  &-inner {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    cursor: pointer;
  }

  &-infos {
    padding: 15px 20px 15px;
    position: relative;
    flex: 1;
  }

  &-provider {
    padding-left: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 23px;
    flex-shrink: 0;
    max-width: 45px;

    img {
      max-width: 100%;
      margin-top: -2px;
    }
  }

  &-top {
    display: flex;
    flex: 1;
  }

  &-time {
    @include font-medium;
  }

  &-select {
    @extend %is-font-extralarge;

    &-not-available {
      color: $red;
    }
  }

  &-location {
    @extend %is-font-small-strict;

    line-height: 14px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    span {
      max-width: 45%;

      &:last-child {
        text-align: right;
      }
    }
  }

  &-footer {
    display: flex;
  }

  @include media-breakpoint-down(sm) {
    @media screen {
      &-footer {
        flex-basis: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 8px 20px;
        border-top: 1px solid $border-gray;
      }

      &-indicator {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid $border-gray;
        padding: 4px 0;
        flex-basis: 100%;

        i {
          color: $light-gray;
          font-size: 24px;
        }
      }

      &-is-open-details {
        display: block;
        position: fixed;
        height: 100vh;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        max-width: 100vw;
        overflow: scroll;
        background: white;
        z-index: 200;
        padding-top: $header-height-mobile;
        cursor: default;

        .tcp-trip {
          &-inner {
            border-bottom: 1px solid $border-gray;
          }
          &-footer {
            display: none;
          }
        }
      }
    }

  }


  @include media-breakpoint-up(sm) {
    border-radius: 5px;

    &-inner {
      border-bottom: 0;
      padding: 0 25px 0 20px;
      justify-content: flex-end;
      cursor: default;
      flex-wrap: nowrap;
    }

    &-provider, &-infos, &-time, &-select {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    &-infos {
      margin-right: 50px;
      padding-left: 0;
      padding-right: 0;
    }

    &-time {
      @include font-large;
      display: block;
      padding-right: 35px;
    }

    &-provider {
      padding-left: 0;
      padding-right: 25px;
      margin-top: 0;
      align-items: center;
      max-width: 60px;

      img {
        margin-top: -2px;
      }
    }

    &-select {
      width: 204px;
      padding-left: 20px;
      padding-right: 0;
      text-align: right;
      display: flex;
      flex-direction: column;
      border-left: 1px solid $border-gray;

      .button {
        margin-top: auto;
        margin-left: auto;
        min-width: 150px;
      }
    }
  }
}
