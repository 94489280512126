@import "resources.scss";
.lp-sortbar {
  display: flex;
  height: 51px;
  align-items: center;
  margin-left: 20px;
  padding-bottom: 15px;
  overflow-x: scroll;

  &-label {
    margin-right: 20px;
    white-space: nowrap;
  }

  &-button {
    @include tn(border-radius);

    border-radius: 2px;
    border: 1px solid $border-gray;
    background: white;
    padding: 0 20px;
    height: 100%;
    font-weight: bold;
    white-space: nowrap;

    &.is--active, &:hover {
      border-color: $dark-gray;
    }

    & + & {
      margin-left: 10px;
    }
  }
}
