@import "resources.scss";
.button {
    @include tn(background color border-color, .3s .3s .3s, 0s 0s 0s, ease-in-out ease-in-out ease-in-out);
    color: #fff;
    cursor: pointer;
    background: $black;
    border: 1px solid $black;
    border-radius: 4px;
    display: flex;
    font-size: 12px;
    font-weight: 600;
    height: 32px;
    justify-content: center;
    align-items: center;
    min-width: 160px;
    padding: 0 25px;
    text-transform: uppercase;
    white-space: nowrap;

    &:hover {
        background: black;
        border: 1px solid black;
        color: #fff;
        text-decoration: none;

        .icon-arrow_forward {
            transform: translateX(5px);
        }

        .icon-arrow_downward {
            transform: translateY(3px);
        }

        .icon-arrow_upward {
            transform: translateY(-3px);
        }
    }

    &:disabled {
        background: $gray;
        border-color: $gray;
        cursor: auto;
        opacity: 1;

        .icon-arrow_forward {
            transform: translateX(0);
        }
    }

    i {
        @include tn(transform, 200ms);

        position: relative;
        margin-left: 6px;
        // margin-right: 6px;
        font-size: 14px;
    }

    span {
        line-height: 14px;
        display: flex;
        align-items: center;
    }

    // .icon-arrow_forward {
    // }

    &-light {
        border: 1px solid $black;
        background: white;
        color: $black;
    }

    &-large {
      height: 36px;
      font-size: 15px;
    }

    &.is--large {
      @include subtitle2;
      height: $new-input-height;
      width: 100%;
      text-transform: unset;
    }

    &.is--medium {
      @include caption;
      height: 36px;
      text-transform: unset;
    }

    @include media-breakpoint-up(sm) {
        min-width: auto;
    }
}
