//  ================================================
//  Typography
//  ================================================

//  ------------------------------------------------
//  Font-face
//  ------------------------------------------------

//@include font-face(Avenir, 'avenir-light', $weight: 100);

/*
    Information: only general font-styles
*/

//  ------------------------------------------------
//  Body
//  ------------------------------------------------

body {
  @include body;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $body;
  font-family: $base-font;
  letter-spacing: 0.1px;
}

button,
input {
  font-family: $base-font;
  font-size: 14px;
}

//  ------------------------------------------------
//  Headlines
//  ------------------------------------------------

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1 {
}

h2 {
  @extend %is-font-large;
  font-weight: bold;
  margin-bottom: 20px;
  // text-transform: uppercase;
}

input {
  font-family: $base-font;
  color: $body;
}

b {
  font-weight: 500;
}

.light {
  color: $gray;
}

.is-underlined {
  @extend %is-underlined;
}

.is-bold {
  @extend %is-bold;
}

.is-normal {
  @extend %is-normal;
}

.is-font-small {
  @include font-small;
}

.is-font-medium {
  @include font-medium;
}

.is-font-large {
  @include font-large;
}

.color- {
  &green,
  &blue {
    color: $blue;
  }

  &yellow {
    color: $yellow;
  }

  &red {
    color: $red;
  }

  &gray {
    color: $gray;

    .is-underlined {
      border-color: $gray;
    }
  }
}

.align-- {
  &left {
    text-align: left;
  }

  &right {
    text-align: right;
  }

  &center {
    text-align: center;
  }
}
