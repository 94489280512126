@import "resources.scss";
.lp-checkout-button-link {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 12px;
}

.lp-checkout-button-sub-header {
  @include font-small;
  font-weight: bold;
  text-align: right;
  color: $red;
}