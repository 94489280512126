@import "resources.scss";
.tcp-transport-segment-ticket-images {
    display: none;

    &-item {
        @extend %is-font-small;
    }

    @include media-breakpoint-up(sm) {
        display: flex;
        margin-bottom: 20px;
        justify-content: space-between;
        align-items: center;

        img {
            max-height: 37px;
            max-width: 80px;
            margin-left: 15px;
        }

        &-main {}

        &-item {
            display: flex;
            flex-direction: column;
        }
    }
}
