@import "resources.scss";
.tcp-menu {
    @include tn(transform);
    box-shadow: rgba(0, 0, 0, 0.15) 8px 0 20px 0;
    transform: translateX(-300px);
    position: fixed;
    left: 0;
    width: 280px;
    top: 0;
    bottom: 0;
    z-index: 300;
    background: white;
    line-height: 1;

    &-group {
        border-bottom: 1px solid $lighter-gray;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 20px;

        &:last-child {
          border-bottom: 0;
        }
    }
    &-item {
        cursor: pointer;
        display: flex;
        font-weight: bold;
        width: 100%;
        line-height: 17px;
        i {
            margin-right: 13px;
            font-size: 18px;
        }
    }
    &-list {
        padding-top: 10px;
        padding-left: 20px;
        &-item {
            padding: 10px 0;
            cursor: pointer;
            i {
                margin-right: 7px;
            }
        }
    }
    &-open {
        transform: translateX(0);
    }
}

.tcp-header-mobile {
    display: flex;
    height: $header-height-mobile;
    line-height: 1;
    position: fixed;
    width: 100%;
    z-index: 10;
    top: 0;
    background: white;
    padding: 0 20px 0px 0px;
    align-items: center;
    justify-content: space-between;

    &-button {
        margin-left: auto;
        .icon-menu, .icon-close2 {
            @include tn(opacity);
            font-size: 26px;
        }
        &:hover {
            .icon-menu {
                opacity: 0.8;
            }
        }
    }
    &-edit {
        text-transform: uppercase;
        font-size: 12px;
    }
    &-label {
        @include pos(center);
        font-size: 15px;
        text-transform: uppercase;
    }

    &.has--co-logo {
      border-bottom: 1px solid $grey50;
    }

    &.in--webview {
        position: absolute;
    }
}
