//  ------------------------------------------------
//  Colors
//  ------------------------------------------------
$border-gray: rgba(0, 0, 0, 0.1);
$lighter-gray: #f2f2f3; //#6c6c6e;
$light-gray: #bfbfbf;
$gray: #7f7f7f;
$blue: #0172b1;
$yellow: #fac734;
$red: #dd3728;

$dark-gray: #191f25;
$black: $dark-gray;
$body: $dark-gray;
$white: #fff !default;

$gray-background: #f7f7f7;

$hotels: $body;
$checkout: $body;

$button: #3e3d40;

$shadow: rgba(0, 0, 0, 0.1);

$green: #7cb342;

// new colors
$black50: #F6F9FC;


$grey100: #edf5fd;
$grey200: #e5edf5;
$grey300: #d6dee6;
$grey500: #949ba3;
$grey700: #585f66;
$grey50: #f4f7f9;

$blue50: #e1f4fc;
$blue900: #004781;

$shadow02: 0px 2px 4px 0px rgba(25, 31, 37, 0.1);

$shadow08: 0px 8px 16px rgba(25, 31, 37, 0.1);

