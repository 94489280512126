@import "resources.scss";
.tcp-transport-segment {
  @include tn(opacity);

  &-item {
    // padding-left: 22px;
    position: relative;
    height: 55px;
    line-height: 14px;
    padding-bottom: 20px;
    padding-left: 80px;

    &:before {
      content: "";

      @include pos(6px n n 0px, $z: 1);
      @include circle(9px);

      border: 1px solid $dark-gray;
      background-color: #fff;
    }

    &:after {
      content: "";

      @include pos(6px n -6px 3px);

      border-left: 1px solid $dark-gray;
    }

    &:before {
      @include pos(3px n n 62px, $z: 1);
    }

    &:after {
      @include pos(6px n -6px 66px);
    }

    &:last-child {
      height: auto;
      padding-bottom: 0;

      .tcp-transport-segment-direction {
        margin-bottom: 3px;
      }

      .tcp-transport-segment-location {
        margin-bottom: 0;
      }

      &:after {
        display: none;
      }
    }

    &-change {
      &:before {
        content: "";

        @include pos(6px n n 0px, $z: 1);
        @include circle(8px);

        border: 2px solid $dark-gray;
        background-color: #fff;
      }
    }
  }

  &-time {
    margin-right: 10px;
    @include pos(0 n n 0);

  }

  &-location {
    padding-bottom: 5px;
    display: inline-block;

    &-platform {
      top: -20px;
      position: relative;
    }
  }

  &-inner {
    position: relative;
    top: -17px;
  }

  &-direction {
    display: flex;
    height: 14px;
    align-items: center;

    img {
      margin-right: 5px;
      margin-top: -2px;
    }

    i {
      font-size: 14px;
      margin-right: 5px;
      padding-left: 0;
      vertical-align: middle;
    }

    div + div {
      &:before {
        content: "-";
        display: inline-block;
        margin: 0 5px;
      }
    }
  }

  .product-icon {
    height: 14px;
    width: 14px;
  }

  @include media-breakpoint-up(sm) {
    &-item {
      width: 100%;

      &:before {
        @include pos(3px n n 58px, $z: 1);
      }

      &:after {
        @include pos(6px n -6px 62px);
      }

      &-change {
        @include make-icon($icon-repeat);

        &:before {
          font-size: 10px;
          color: #c6c7c8;
          top: 5px;
          left: 56px;
          border-radius: 0;
        }
      }
    }

    &-time {

      // Fixing AM PM out of shape
      // margin-left: -10px;

    }

    &-location {
      display: flex;
      padding-right: 15px;
      margin-bottom: 3px;
      align-items: flex-start;
    }

    &-direction {
      color: $gray;
    }
  }
}

.is--collapsed .tcp-transport-segment {
  height: 0;
  overflow: hidden;
  opacity: 0;

  &-item {
    padding-bottom: 0;
  }

  &-item:first-child:after {
    border-left: 1px dashed $light-gray;
  }

  &-item:before {
    border-color: $light-gray;
  }

  &-direction {
    opacity: 0;
  }

  &-expand-icon {
    @include make-icon($icon-angle-down);
  }

  &:first-child, &:last-child {
    height: auto;
    opacity: 1;
  }

  &:first-child {
    .tcp-transport-segment-item:last-child {
      height: 0;
      opacity: 0;
    }
  }

  &:last-child {
    margin-top: -6px;

    .tcp-transport-segment-item:first-child {
      height: 0;
      opacity: 0;
    }
  }
}
