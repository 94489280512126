.l-row {
  display: flex;
  flex-wrap: wrap;

  &.has-margin {
    @include make-row;
  }

  @include media-breakpoint-up(sm) {
    min-width: 1200px;
    max-width: 1440px;
  }
}

.main-container {
  position: relative;
  padding-top: $header-height;

  &.without-nav-spacing {
    padding-top: 0px;
  }

  @include media-breakpoint-down(sm) {
    padding-top: $header-height-mobile;

    &.no-scrolling {
      padding-top: $header-height;
      position: fixed;
      overflow: hidden;
      max-height: 100vh;
      z-index: 10;
    }
  }
}

.container-fluid {
  max-width: 1200px;
}


#launcher {
  z-index: 0 !important;
  @include media-breakpoint-up(sm) {
    right: 50px!important;
  }
}
