@import "resources.scss";
.lp-search-bar-concierge {
  background-size: auto 80%;
  background-position: bottom right;
  padding: 0 24px;
  cursor: pointer;
  display: flex;
  position: relative;

  &-image {
    background: url(../../../assets/img/backgrounds/concierge.png) no-repeat center / cover;
    width: 82px;
    height: 82px;
    flex-shrink: 0;
  }

  &-inner {
    padding-left: 24px;
  }

  &-desc {
    @include caption;
    margin-top: 14px;
  }

  &-item {
    display: flex;
    padding: 16px 16px 20px 16px;
    align-items: flex-start;
    border-radius: 8px;
    background: $grey100;
    margin-bottom: 12px;

    &:hover {
      background: $grey200;
    }
  }

  &-title {
    @include subtitle1;
    margin-bottom: 4px;
  }

  &-icon {
    height: 32px;
    width: 32px;
    border: 1px solid $grey700;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    flex-shrink: 0;
    background: white;

    svg {
      width: 16px;
    }
  }

  &-footer {
    text-align: center;
    color: $blue;
    padding: 20px 20px 0;
    display: block;

    i {
      margin-bottom: 10px;
      font-size: 18px;
    }

    &-title {
      font-weight: bold;
    }
  }

  &-services {
    padding-top: 8px;
  }
}
