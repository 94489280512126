@import "resources.scss";
.tcp-leg-waypoint {
    position: relative;
    height: 22px;

    &:before,
    &:after {
        content: "";
    }

    &-icon {
        display: inline-block;
        color: $button;
        text-align: center;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        border: 1px solid $button;
        background-color: #fff;
        height: 7px;
        width: 7px;
        line-height: 6px;
        top: -1px;

        [class^="icon-"] {
            display: none;
        }
    }

    @include media-breakpoint-up(sm) {
        position: relative;
        height: 26px;

        &:before {
            background-color: $button;
            height: 6px;

            @include pos(10px 9px 0 10px);
        }

        &:after {
            @include pos(10px 5px n n);

            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 3px 0 3px 4px;
            border-color: transparent transparent transparent $button;
        }

        &-icon {
            // border-radius: 50%;
            // border: 1px solid $button;
            // background-color: #fff;
            height: 26px;
            width: 26px;
            line-height: 26px;
            top: 0;
            z-index: 1;

            .icon-bus {
                margin-left: 1px;
            }

            .icon-train {
                font-size: 13px;
            }

            [class^="icon-"] {
                display: inline-block;
                font-size: 14px;

                &:before {
                    margin: 0;
                    vertical-align: baseline;
                }
            }

            .icon-plane {
                font-size: 15px;
                line-height: 15px;
            }
        }
    }
}

.tcp-leg-waypoint-vertical {
    @extend %is-font-large;
    margin-right: 20px;
    top: -4px;

    .tcp-leg-waypoint-icon {
      height: 24px;
      width: 24px;
      line-height: 24px;

      [class^="icon-"] {
        display: inline-block;
        font-size: 14px;
      }
    }

    @include media-breakpoint-up(sm) {
        position: absolute;
        left: 20px;
        display: block;
        height: calc(100% - 30px);
        margin-right: 0;

        // &-icon {
        //     @include pos(0 n n 0);

        //     i {
        //         font-size: inherit;
        //     }
        // }

        &:before {
            background-color: $button;
            height: auto;
            width: 6px;

            @include pos(0 n 4px 10px);
        }

        &:after {
            @include pos(auto n 0 10px);

            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 4px 3px 0 3px;
            border-color: $button transparent transparent transparent;
        }
    }
    &-item-public {
        color: $light-gray;
        &:before {
            background-color: $light-gray;
        }
        &:after {
            border-color: $light-gray transparent transparent transparent;
        }
        &-icon {
            color: $light-gray;
            border-color: $light-gray;
        }
    }
}
