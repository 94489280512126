@import "resources.scss";
.tcp-leg {
    @extend %is-font-medium;

    width: 100%;
    position: relative;
    display: flex;
    padding: 25px 0 20px;

    &-item {
        position: relative;
        display: flex;
        flex-wrap: wrap;

        &-inbound {
            padding-left: 10px;
            flex-wrap: nowrap;

            i.icon-to {
                font-size: 20px;
                background: white;
            }

            .tcp-leg-location {
              padding-left: 12px;
            }

            &:after {
                display: none;
            }

            &:before {
                border-bottom: 0;
                bottom: 15px;
                top: 0;
            }
        }

        &-transfer {
            display: flex;
            justify-content: center;
            align-items: center;

            label {
                margin-bottom: 0;
                margin-right: 20px;
            }

            .tcp-form-group {
                margin-bottom: 0;
            }
        }
    }

    &-item-public {
        .tcp-leg-waypoint {
            &:before {
                background-color: $light-gray;
            }

            &:after {
                border-color: $light-gray transparent transparent transparent;
            }

            &-icon {
                color: $light-gray;
                border-color: $light-gray;
            }
        }
    }

    &-time {
        @extend %is-font-medium;
        display: none;
    }

    &-location {
        @extend %is-font-medium;
        font-weight: bold;
        margin-bottom: 15px;

        &-label {
            margin-right: 15px;
        }
    }

    &-timetable {
        flex-basis: calc(100% - 44px);

        @extend %is-font-medium;
    }

    &-waiting {
        width: 100%;
        display: flex;
        margin-top: 10px;
        padding-left: 44px;

        &__inner {
            @extend %is-font-medium;

            border: 1px solid $lighter-gray;
            padding: 5px 15px;
            text-align: center;
        }
    }

    &-tickets {
        margin-left: 44px;
        margin-top: 15px;
        width: 100%;
        padding: 25px 15px;
        border: 1px solid $border-gray;

        &-operator {
            @extend %is-font-medium;

            display: flex;
            justify-content: space-between;
        }
    }

    &-button {
        padding: 7px 8px;
        min-width: 90px;
    }

    &-button-group {
        display: flex;
        padding: 20px 0 0 44px;
        width: 100%;

        button {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }

        button + button {
            margin-left: 2px;
            border-radius: 0;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
        }

        &:first-child {
            padding: 20px 0 25px 44px;
            border-top: 1px dashed $border-gray;
        }
    }

    &-expand {
        @extend %is-font-medium;
        color: $gray;
        white-space: nowrap;
        margin: 0;
    }

    &-expand-icon {
        @include make-icon($icon-angle-up);
        vertical-align: sub;
        margin-right: 5px;
    }

    &-status {
      @extend %is-font-small;

      border: 1px solid $blue;
      border-radius: 3px;
      font-weight: bold;
      padding: 4px 6px;
      margin-left: 120px;
      display: inline-flex;
      align-items: center;
      margin-top: 25px;

      i {
        color: $blue;
        margin-right: 5px;
      }
    }

    &.is--collapsed {
        .tcp-leg-expand-icon {
            @include make-icon($icon-angle-down);
        }
    }

    @include media-breakpoint-up(sm) {
        border-bottom: 1px dashed $border-gray;

        &-item {
            width: 100%;
            padding-left: 65px;

            &-inbound i {
                position: absolute;
                left: 28px;
            }
        }

        &-item-transfer {
            min-height: 0;
            padding-bottom: 10px;
            padding-top: 10px;

            .select {
                display: inline-block;
                width: auto;
            }
        }

        &-time {
            display: block;
            width: 65px;
            font-weight: bold;
            text-align: right;
        }

        &-timetable {
          flex-basis: 45%;
        }

        &-waiting {
          padding-left: 0;
        }

        &-tickets {
            flex: 1;
            border: 0;
            padding: 0;
            padding-bottom: 20px;
            position: relative;
            margin: 0;
        }

        &-inbound {
            padding-top: 0;
        }

        &-inbound, &-last {
            border-bottom: 0;
        }

        &-button-group{
          padding: 15px 0 0 0;

          &:first-child {
            border: 0;
            padding: 0 0 15px 0;
          }
        }

        &-main {
            .tcp-leg-tickets {
                border-left: 1px solid $border-gray;
                padding-left: 30px;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        &-timetable {
            flex-basis: 55%;
          }
    }
}
